import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import $ from 'jquery';
import { Link, useNavigate } from 'react-router-dom'
import { useDebounce } from 'use-debounce';


function Searching(props) {
    const navigate = useNavigate();
    const [selectedsearch_in_value, setSelectedsearch_in_value] = useState(props.page === undefined ? "Tasks" : props.page);
    const ApiBaseURL_url = process.env.REACT_APP_URL;
    const ClientID = Number(localStorage.getItem('ClientID'));
    const EmailID = localStorage.getItem('Email');
    const UserID = Number(localStorage.getItem('ClientUserID'));
    const [txtsearch, setTxtsearch] = useState("");
    const [searchResult, setSearchResult] = useState([]);
    const [value] = useDebounce(txtsearch, 1000);

    const [isLoading, setIsLoading] = useState(true);
    const [shownodatafound, setShownodatafound] = useState(false);
    const ipAddress = localStorage.getItem("IP_ADDRESS");
    //  $("#view-member_alltab").prependTo("body");

    useEffect(() => {
        console.log(value)
        search_fn(value)
    }, [value])

    const search_fn = async (e) => {
        try {
            setIsLoading(false);
            var value = e;
            const requestOptions = {
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('AccessToken')
                }
            }
            if (value.length > 0) {
                $("#myModal").show()
                if (selectedsearch_in_value === "Tasks") {
                    const response = await fetch(`${ApiBaseURL_url}search/searchintask?email=${EmailID}&ipAddress=${ipAddress}&clientid=${ClientID}&userid=${UserID}&searchtext=${value}`, requestOptions)
                    const result = await response.json();
                    if (response.ok) {
                        console.log(result.searchlists)
                        setSearchResult(result.searchlists);
                        setIsLoading(true);
                        if (result.searchlists != null)
                            setShownodatafound(result.searchlists.length === 0 ? true : false);
                        else
                            setShownodatafound(true);
                    }
                    else if (response.status === 400) {
                        toast.error(result.errors)
                    }
                    else if (response.status === 401) {
                        toast.error('Your Session has been expired, Please login again.');
                        window.setTimeout(function () {
                            localStorage.clear();
                            navigate("/login");
                        }, 2000);
                    }
                    else {
                        //setisLoaded(true)
                    }
                }
                else if (selectedsearch_in_value === "Meetings") {
                    const response = await fetch(`${ApiBaseURL_url}search/searchinmeeting?email=${EmailID}&ipAddress=${ipAddress}&clientid=${ClientID}&userid=${UserID}&searchtext=${value}`, requestOptions)
                    const result = await response.json();
                    if (response.ok) {
                        setSearchResult(result.searchlists)
                        setIsLoading(true);
                        if (result.searchlists != null)
                            setShownodatafound(result.searchlists.length === 0 ? true : false);
                        else
                            setShownodatafound(true);
                    }
                    else if (response.status === 400) {
                        toast.error(result.errors)
                    }
                    else if (response.status === 401) {
                        toast.error('Your Session has been expired, Please login again.');
                        window.setTimeout(function () {
                            localStorage.clear();
                            navigate("/login");
                        }, 2000);
                    }
                    else {
                        //setisLoaded(true)
                    }
                }
                else if (selectedsearch_in_value === "Teams") {
                    localStorage.setItem("HeaderList", null);
                    const response = await fetch(`${ApiBaseURL_url}search/searchinteams?email=${EmailID}&ipAddress=${ipAddress}&clientid=${ClientID}&userid=${UserID}&searchtext=${value}`, requestOptions)
                    const result = await response.json();
                    if (response.ok) {
                        setSearchResult(result.searchlists)
                        setIsLoading(true);
                        if (result.searchlists != null)
                            setShownodatafound(result.searchlists.length === 0 ? true : false);
                        else
                            setShownodatafound(true);
                    }
                    else if (response.status === 400) {
                        toast.error(result.errors)
                    }
                    else if (response.status === 401) {
                        toast.error('Your Session has been expired, Please login again.');
                        window.setTimeout(function () {
                            localStorage.clear();
                            navigate("/login");
                        }, 2000);
                    }
                    else {
                        //setisLoaded(true)
                    }
                }
                else if (selectedsearch_in_value === "Projects") {
                    localStorage.setItem("HeaderList", null);
                    const response = await fetch(`${ApiBaseURL_url}search/searchinproject?email=${EmailID}&ipAddress=${ipAddress}&clientid=${ClientID}&userid=${UserID}&searchtext=${value}`, requestOptions)
                    const result = await response.json();
                    if (response.ok) {
                        setSearchResult(result.searchlists)
                        setIsLoading(true);
                        if (result.searchlists != null)
                            setShownodatafound(result.searchlists.length === 0 ? true : false);
                        else
                            setShownodatafound(true);
                    }
                    else if (response.status === 400) {
                        toast.error(result.errors)
                    }
                    else if (response.status === 401) {
                        toast.error('Your Session has been expired, Please login again.');
                        window.setTimeout(function () {
                            localStorage.clear();
                            navigate("/login");
                        }, 2000);
                    }
                    else {
                        //setisLoaded(true)
                    }
                }
            }
            else {
                setSearchResult([]);
                setIsLoading(true);
                $("#myModal").hide()
            }


        } catch (error) {
            toast.error('Something went wrong , Please try again later.')
        }

    }

    const openUpdateIssue = (id) => {
        localStorage.setItem('issueid', id);
    }

    const openUpdatetask = (id) => {
        localStorage.setItem('projecttaskid', id);
    }

    const closeSerachModal = (id) => {
        //window.$("#myModal").modal("hide");
        $("#myModal").hide()
        setTxtsearch("");
        if (selectedsearch_in_value == "Tasks") {
            navigate('/tasks/' + id)
        }
        else if (selectedsearch_in_value == "Meetings") {
            navigate('/meeting-details/' + id)
        }
        else if (selectedsearch_in_value == "Teams") {
            navigate('/teams/' + id)
        }
    }
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleChevron = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <>
            <div className="nav-searchbox-header inline-block">
                <div className="input-group">
                    <div className="input-group-btn search-panel-header" data-search="students">
                        {/* <a type="button" className="btn btn-default ddlbtn dropdown-toggle" data-toggle="dropdown">
                            <span className="glyphicon glyphicon-search"></span>
                            <span className="search_by">Search in <span style={{color:'#028090'}}>{selectedsearch_in_value}</span></span> <span className="caret"></span>
                        </a> */}
                        <a type="button" className="btn  headersearch dropdown-toggle search-panel-header w-123px" data-toggle="dropdown" onClick={toggleChevron}>
                            <span style={{ marginRight: '15px' }}>  <span>{selectedsearch_in_value} </span> </span>
                            <i className={`fa ${isExpanded ? "fa-chevron-up" : "fa-chevron-down"}`}
                                onClick={toggleChevron}
                                style={{ cursor: "pointer" }} // Optional styling
                            ></i>
                            <span className='search-seprator'> | </span>
                        </a>
                        <ul className="dropdown-menu my-dropdown  dropdown-menu-right topsearch mt-10" role="menu">
                            <li><a onClick={() => setSelectedsearch_in_value("Tasks")}><img alt="uniteamImage" src="/images/tasks/g-check.svg" />Tasks</a></li>
                            <li><a onClick={() => setSelectedsearch_in_value("Meetings")}><img alt="uniteamImage" src="/images/tasks/calander-g.svg" />Meetings</a></li>
                            <li><a onClick={() => setSelectedsearch_in_value("Teams")} ><img alt="uniteamImage" src="/images/tasks/teams-g.svg" />Teams</a></li>
                            {/* <li><a onClick={() => setSelectedsearch_in_value("Projects")} ><img alt="uniteamImage" src="/images/tasks/flag-g.svg" />Projects</a></li> */}
                        </ul>

                    </div>
                    <input type="text" className="searchbox-header b-left" autoComplete="off" id="searchfield" placeholder="Search..." onChange={(e) => setTxtsearch(e.target.value)} />
                    <span className="glyphicon glysearch"><i class="fa fa-search" onClick={()=>search_fn(txtsearch)}></i></span>
                    {/* <input type="text" autoComplete="off" className="searchbox b-left" id="searchfield" placeholder="" onChange={(e) => search_fn(e)} /> */}
                </div>
            </div>

            <div className="" id="myModal" hidden style={{ position: 'absolute' }}>
                <div className="modal-dialog modal-dialog-scrollable  modal-sm" style={{ width: '430px' }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <span className="searchtype">{selectedsearch_in_value}</span>
                            <button type="button" className="close" data-dismiss="modal" onClick={() => $("#myModal").hide()}><img alt="uniteamImage" src="/images/icons/close.png"></img></button>
                        </div>
                        <div className="modal-body">
                            <div className="container container-search">
                                {
                                    isLoading === false ?
                                        <div className="loaderSearch" ><img alt="uniteamImage" src="/images/progress.gif" width="30px" /></div>
                                        :
                                        (searchResult !== undefined && searchResult !== null && searchResult.length > 0)
                                            ?
                                            searchResult.map(item => (
                                                <div className="searchresults">
                                                    {
                                                        (item.type === "Task" && (item.relatedprojectid === null || item.relatedprojectid === 0) && selectedsearch_in_value !== "Projects")
                                                        &&
                                                        <a onClick={() => closeSerachModal(item.id)}>
                                                            <div className="row resultitem">
                                                                <div className="col-md-9">
                                                                    <p className="createdby">{item.taskcreatedby_name}</p>
                                                                    <p className="title">{item.title}{selectedsearch_in_value === "Projects" ? <><br /><small style={{ color: "grey" }}>{item.relatedprojectname}</small></> : ''}</p>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <p className="createdon">{item.taskcreatedon}</p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    }

                                                    {
                                                        (item.type === "Task" && item.relatedprojectid !== null && item.relatedprojectid !== 0 && selectedsearch_in_value === "Projects")
                                                        &&
                                                        <a to={`/project-tasks/${item.relatedprojectid}`} onClick={(e) => openUpdatetask(item.id)}>
                                                            <div className="row resultitem">
                                                                <div className="col-md-9">
                                                                    <p className="createdby">{item.taskcreatedby_name}</p>
                                                                    <p className="title">{item.title}{selectedsearch_in_value === "Projects" ? <><br /><small style={{ color: "grey" }}>{item.relatedprojectname}</small></> : ''}</p>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <p className="createdon">{item.taskcreatedon}</p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    }

                                                    {
                                                        item.type === "Meeting"
                                                        &&
                                                        <a //to={`/meetings/${item.id}`}
                                                            onClick={() => { closeSerachModal(item.id) }}
                                                        >
                                                            <div className="row resultitem">
                                                                <div className="col-md-9">
                                                                    <p className="createdby">{item.meetingcreatedby_name}</p>
                                                                    <p className="title">{item.title}</p>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <p className="createdon">{item.meetingcreatedon}</p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    }

                                                    {/* {
                                                        item.type === "User"
                                                        &&
                                                        <a //to={`/teams/all/${item.memberemail}`}
                                                        onClick={()=>{closeSerachModal(item.id)}}>
                                                            <div className="row resultitem">
                                                                <div className="col-md-8">
                                                                    <p className="title">{item.title}</p>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <p className="type">{item.type}</p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    } */}

                                                    {
                                                        item.type === "Team"
                                                        &&
                                                        <a //to={`/teams/${item.id}`} 
                                                            onClick={() => { closeSerachModal(item.id) }}>
                                                            <div className="row resultitem">
                                                                <div className="col-md-9">
                                                                    <p className="title">{item.title}</p>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <p className="type">{item.type}</p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    }

                                                    {
                                                        item.type === "Project"
                                                        &&
                                                        <Link to={`/project-milestones/${item.id}`}>
                                                            <div className="row resultitem">
                                                                <div className="col-md-9">
                                                                    <p className="createdby"></p>
                                                                    <p className="title">{item.title}</p>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <p className="createdon"></p>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    }

                                                    {
                                                        item.type === "Milestone"
                                                        &&
                                                        <Link to={`/project-milestones/${item.relatedprojectid}`}>
                                                            <div className="row resultitem">
                                                                <div className="col-md-9">
                                                                    <p className="createdby"></p>
                                                                    <p className="title">{item.title}<br /><small style={{ color: "grey" }}>{item.relatedprojectname}</small></p>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <p className="createdon"></p>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    }

                                                    {
                                                        item.type === "Issue"
                                                        &&
                                                        <Link to={`/project-issues/${item.relatedprojectid}`} onClick={(e) => openUpdateIssue(item.id)}>
                                                            <div className="row resultitem">
                                                                <div className="col-md-9">
                                                                    <p className="createdby"></p>
                                                                    <p className="title">{item.title}<br /><small style={{ color: "grey" }}>{item.relatedprojectname}</small></p>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <p className="createdon"></p>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    }
                                                </div>
                                            )) : <h5>No Match Found.</h5> 
                                }

                            </div>
                        </div>

                    </div>
                </div>
            </div>


        </>

    )
}

export default React.memo(Searching)